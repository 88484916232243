<template>
  <div class="py-20">
    <div class="block mx-auto max-w-7xl">
      <div class="text-center">
        <img class="mx-auto h-52" src="@/assets/images/titles/roadmap.svg" alt="">
      </div>
      <div class="block mt-20 text-white">
        <div class="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
          <!-- right -->
          <div class="flex md:contents">
            <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-black-100 pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-black-100 shadow"></div>
            </div>
            <div class="bg-black-100 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
              <h3 class="font-semibold text-2xl londrina mb-1">20%</h3>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    50 NFTs will be randomly raffled among Discord District Denizens
                  </span>
                </div>
              </div>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    LotterEE : three random lucky holders of Eternal Entities will receive a percentage of the EE proceeds!
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- left -->
          <div class="flex flex-row-reverse md:contents">
            <div class="bg-black-100 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
              <h3 class="font-semibold text-2xl londrina mb-1">40%</h3>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    50 NFTs will be randomly raffled among Discord District Denizens
                  </span>
                </div>
              </div>
            </div>
            <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-black-100 pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-black-100 shadow"></div>
            </div>
          </div>
          <div class="flex md:contents">
            <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-black-100 pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-black-100 shadow"></div>
            </div>
            <div class="bg-black-100 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
              <h3 class="font-semibold text-2xl londrina mb-1">60%</h3>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                      50 NFTs will be randomly raffled among Discord District Denizens.
                    </span>
                </div>
              </div>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    In honor of Stephen Hawking and everyone suffering from this terrible <br>  disease: a 45.000 USD donation to the ALS association.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row-reverse md:contents">
            <div class="bg-black-100 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
              <h3 class="font-semibold text-2xl londrina mb-1">80%</h3>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    PHASE II initiated.
                  </span>
                </div>
              </div>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    Eternal Equity Programme with participating Community members.
                  </span>
                </div>
              </div>
            </div>
            <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-black-100 pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-black-100 shadow"></div>
            </div>
          </div>
          <div class="flex md:contents">
            <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-black-100 pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-black-100 shadow"></div>
            </div>
            <div class="bg-black-100 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
              <h3 class="font-semibold text-2xl londrina mb-1">100%</h3>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    Official EE MERCH!
                  </span>
                </div>
              </div>
              <div class="block">
                <div class="flex">
                  <span class="cursor-pointer w-2 h-2 mt-2 bg-white border-gray-200 border rounded-full hover:bg-gray-200"></span>
                  <span class="pl-3 roboto">
                    Development of a P2E Eternal Entities Arcade Hall, <br> with games like Wiener Whacker, the nr. 1 ranked realistic <br> penis pounding simulator, featuring the Boner Butcher and the Shaft Smasher.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Roadmap.vue"
}
</script>

<style scoped>

</style>
