<template>
  <footer class="bg-black-100">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <div class="mt-8 block lg:flex">
        <div>
          <div class="flex">
            <a href="https://discord.gg/QKcSD7FM46" target="_blank" class="text-white hover:text-gray-500">
              <span class="sr-only">Discord</span>
              <svg width="42" height="33" viewBox="0 0 112 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M74.1406 60.325C74.1406 64.1985 71.2863 67.3735 67.6709 67.3735C64.1189 67.3735 61.2011 64.1985 61.2011 60.325C61.2011 56.4515 64.0554 53.2765 67.6709 53.2765C71.2863 53.2765 74.1406 56.4515 74.1406 60.325ZM44.5194 53.2765C40.904 53.2765 38.0497 56.4515 38.0497 60.325C38.0497 64.1985 40.9674 67.3735 44.5194 67.3735C48.1349 67.3735 50.9891 64.1985 50.9891 60.325C51.0526 56.4515 48.1349 53.2765 44.5194 53.2765ZM111.5 13.081V127C95.5205 112.863 100.631 117.542 82.0691 100.267L85.4309 112.014H13.5029C6.33543 112.014 0.5 106.172 0.5 98.933V13.081C0.5 5.842 6.33543 0 13.5029 0H98.4971C105.665 0 111.5 5.842 111.5 13.081ZM93.4229 73.279C93.4229 52.832 84.2891 36.2585 84.2891 36.2585C75.1554 29.4005 66.4657 29.591 66.4657 29.591L65.5777 30.607C76.3606 33.909 81.3714 38.6715 81.3714 38.6715C66.3044 30.4043 48.6056 30.4029 33.9903 36.83C31.6434 37.9095 30.248 38.6715 30.248 38.6715C30.248 38.6715 35.5126 33.655 46.9297 30.353L46.2954 29.591C46.2954 29.591 37.6057 29.4005 28.472 36.2585C28.472 36.2585 19.3383 52.832 19.3383 73.279C19.3383 73.279 24.6663 82.4865 38.684 82.931C38.684 82.931 41.0309 80.0735 42.9337 77.6605C34.8783 75.2475 31.8337 70.1675 31.8337 70.1675C32.7668 70.8214 34.3055 71.6689 34.4343 71.755C45.1403 77.7572 60.3478 79.7238 74.0137 73.9775C76.2337 73.152 78.7074 71.9455 81.308 70.231C81.308 70.231 78.1366 75.438 69.8274 77.7875C71.7303 80.2005 74.0137 82.931 74.0137 82.931C88.0314 82.4865 93.4229 73.279 93.4229 73.279Z" fill="#F8F9FA"/>
              </svg>
            </a>

            <a href="https://twitter.com/EternalNFTs" target="_blank" class="ml-12 lg:ml-3 text-gray-400 hover:text-gray-500">
              <span class="sr-only">Twitter</span>
              <svg width="42" height="33" viewBox="0 0 42 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.6828 8.22419C37.7095 8.58511 37.7095 8.94611 37.7095 9.30703C37.7095 20.3156 29.0484 33 13.2183 33C8.34138 33 3.81094 31.6335 0 29.2618C0.692921 29.3391 1.3591 29.3648 2.07868 29.3648C6.10274 29.3648 9.80712 28.05 12.7653 25.8071C8.98098 25.7297 5.80964 23.3321 4.71698 20.032C5.25002 20.1093 5.78298 20.1609 6.34268 20.1609C7.1155 20.1609 7.8884 20.0577 8.6079 19.8774C4.66374 19.1039 1.70552 15.7523 1.70552 11.7047V11.6016C2.85142 12.2203 4.18402 12.6071 5.59636 12.6586C3.27782 11.1632 1.75884 8.6109 1.75884 5.72338C1.75884 4.17653 2.18516 2.75856 2.9314 1.52106C7.16874 6.57418 13.5381 9.87412 20.6802 10.2351C20.5469 9.61637 20.467 8.9719 20.467 8.32735C20.467 3.73824 24.3046 0 29.0749 0C31.5533 0 33.7918 1.00547 35.3642 2.62969C37.3096 2.26877 39.1751 1.57264 40.8274 0.618755C40.1878 2.55239 38.8287 4.17661 37.0432 5.20779C38.7754 5.02741 40.4544 4.56324 42 3.91878C40.8276 5.56871 39.3618 7.03818 37.6828 8.22419Z" fill="#F8F9FA"/>
              </svg>
            </a>

            <a href="https://instagram.com/eternalentitiesnft?utm_medium=copy_link" target="_blank" class="ml-12 lg:ml-3 text-white hover:text-gray-500">
              <span class="sr-only">Instagram</span>
              <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5044 9.50089C13.9708 9.50089 9.50747 13.9652 9.50747 19.5C9.50747 25.0348 13.9708 29.4991 19.5044 29.4991C25.0379 29.4991 29.5012 25.0348 29.5012 19.5C29.5012 13.9652 25.0379 9.50089 19.5044 9.50089ZM19.5044 26.0007C15.9284 26.0007 13.0051 23.0854 13.0051 19.5C13.0051 15.9146 15.9197 12.9993 19.5044 12.9993C23.089 12.9993 26.0036 15.9146 26.0036 19.5C26.0036 23.0854 23.0803 26.0007 19.5044 26.0007ZM32.2419 9.09188C32.2419 10.3885 31.1978 11.4241 29.9102 11.4241C28.6138 11.4241 27.5784 10.3798 27.5784 9.09188C27.5784 7.80392 28.6225 6.75962 29.9102 6.75962C31.1978 6.75962 32.2419 7.80392 32.2419 9.09188ZM38.863 11.4589C38.7151 8.33477 38.0016 5.56739 35.7134 3.28735C33.4339 1.00731 30.6671 0.293707 27.5436 0.137063C24.3244 -0.0456878 14.6756 -0.0456878 11.4564 0.137063C8.34161 0.285005 5.57485 0.998605 3.28661 3.27865C0.998383 5.55869 0.293642 8.32606 0.137033 11.4502C-0.0456776 14.6701 -0.0456776 24.3212 0.137033 27.5411C0.284941 30.6652 0.998383 33.4326 3.28661 35.7127C5.57485 37.9927 8.33291 38.7063 11.4564 38.8629C14.6756 39.0457 24.3244 39.0457 27.5436 38.8629C30.6671 38.715 33.4339 38.0014 35.7134 35.7127C37.9929 33.4326 38.7064 30.6652 38.863 27.5411C39.0457 24.3212 39.0457 14.6788 38.863 11.4589ZM34.7041 30.9959C34.0255 32.7016 32.7117 34.0157 30.9977 34.7032C28.4311 35.7214 22.3407 35.4864 19.5044 35.4864C16.668 35.4864 10.5689 35.7127 8.01099 34.7032C6.30569 34.0244 4.99191 32.7103 4.30457 30.9959C3.28661 28.4287 3.52153 22.337 3.52153 19.5C3.52153 16.663 3.29532 10.5626 4.30457 8.00407C4.98321 6.29839 6.29699 4.98432 8.01099 4.29683C10.5776 3.27865 16.668 3.51361 19.5044 3.51361C22.3407 3.51361 28.4398 3.28735 30.9977 4.29683C32.703 4.97562 34.0168 6.28969 34.7041 8.00407C35.7221 10.5713 35.4872 16.663 35.4872 19.5C35.4872 22.337 35.7221 28.4374 34.7041 30.9959Z" fill="#F8F9FA"/>
              </svg>
            </a>
          </div>
        </div>
        <div class="block lg:flex-1 items-end mt-8 lg:mt-0">
          <img class="float-right" src="@/assets/images/copyrights.svg" alt="">
        </div>
      </div>
    </div>

    <div v-on:click="backToTop" class="bg-green-500 border border-green-500 hover:bg-black-100 hover:text-white transition duration-500 ease-in-out text-center rounded-full float-right cursor-pointer p-3 fixed right-5 bottom-5">
      Back to top
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer.vue",
  methods: {
    backToTop: function() {
      window.scroll({ top:0, left:0, behavior: 'smooth'});
    }
  }
}
</script>

<style scoped>

</style>
