<template>
  <div class="bg-black-100 py-20">
    <div class="block mx-auto max-w-7xl">
      <div class="text-center">
        <img class="mx-auto h-52" src="@/assets/images/titles/team.svg" alt="">
      </div>
      <div class="block mt-20 px-4 md:px-0">
        <div class="block bg-white rounded-2xl">
          <div class="block md:grid md:grid-cols-6 md:gap-0 p-6">
            <div class="md:col-span-1">
              <img class="rounded-2xl w-full" src="@/assets/images/team/el_avatar.jpeg" alt="">
            </div>
            <div class="text-center items-center place-self-center md:col-span-2 py-5 md:py-0">
              <h3 class="text-2xl londrina">El DeBard</h3>
              <h3 class="text-2xl londrina">Concept & Brand Developer</h3>
            </div>
            <div class="md:col-span-3 items-center place-self-center">
              <p class="roboto">
                “As an alumnus of The Academy of Arts & Popular Culture I’ve extensively studied how creative expression influences each aspect of our existence.
                That brings me to the commodification of art; a very intriguing phenomenon and the very proof of OUR power as “consumers”.
                To me, this project is a symbol of symbiosis and appreciation between popular culture and the beholder.
                It’s a beautiful thang!”
              </p>
            </div>
          </div>
        </div>
        <div class="block bg-white mt-8 rounded-2xl">
          <div class="block md:grid md:grid-cols-6 md:gap-0 p-6">
            <div class="md:col-span-1">
              <img class="rounded-2xl w-full" src="@/assets/images/team/sugarstick.jpeg" alt="">
            </div>
            <div class="text-center items-center place-self-center md:col-span-2 py-5 md:py-0">
              <h3 class="text-2xl londrina">Sugar Stick Stamos</h3>
              <h3 class="text-2xl londrina">Graphic Designer</h3>
            </div>
            <div class="md:col-span-3 items-center place-self-center">
              <p class="roboto">
                “With a history of developing products for the likes of Nintendo, Marvel, Disney and Nickelodeon, among others,
                it was always my dream to push their creations to their absolute limit.
                That said limit was reached awfully fast and I lost some quality wood working on those projects.
                The idea and the Team behind EE has reinvigorated my penile presence, and now I am able to give the world something truly extraordinary.
                Jeans Bursters guaranteed.”
              </p>
            </div>
          </div>
        </div>
        <div class="block bg-white mt-8 rounded-2xl">
          <div class="block md:grid md:grid-cols-6 md:gap-0 p-6">
            <div class="md:col-span-1">
              <img class="rounded-2xl w-full" src="@/assets/images/team/sav_avatar.jpeg" alt="">
            </div>
            <div class="text-center items-center place-self-center md:col-span-2 py-5 md:py-0">
              <h3 class="text-2xl londrina">Savblu</h3>
              <h3 class="text-2xl londrina">Backend Developer</h3>
            </div>
            <div class="md:col-span-3 items-center place-self-center">
              <p class="roboto">
                “My duty is to develop the backbone, tooling and secret sauce needed for building equity in this awesome realm.
                As someone with extensive experience in the world of smart contracts but not having dabbled in the NFT space, this is a new and exciting world to me.
                To be introduced to this world through the collaboration with such an epic team of people is an honor and a privilege.”
              </p>
            </div>
          </div>
        </div>
        <div class="block bg-white mt-8 rounded-2xl">
          <div class="block md:grid md:grid-cols-6 md:gap-0 p-6">
            <div class="md:col-span-1">
              <img class="rounded-2xl w-full" src="@/assets/images/team/mentos_avatar.jpeg" alt="">
            </div>
            <div class="text-center items-center place-self-center md:col-span-2 py-5 md:py-0">
              <h3 class="text-2xl londrina">Mentos</h3>
              <h3 class="text-2xl londrina">Frontend Developer</h3>
            </div>
            <div class="md:col-span-3 flex-1 items-center place-self-center">
              <p class="roboto">
                “To participate in this project is truly amazing. These people are so talented and introduced me to the world of NFT's.
                With EE we can express ourselves with humour and jokes.
                It's an amazing opportunity to participate in this project, hopefully you feel the same way.”
              </p>
            </div>
          </div>
        </div>
        <div class="block bg-white mt-8 rounded-2xl">
          <div class="block md:grid md:grid-cols-6 md:gap-0 p-6">
            <div class="col-span-1">
              <img class="rounded-2xl w-full" src="@/assets/images/team/ren.jpeg" alt="">
            </div>
            <div class="text-center items-center place-self-center md:col-span-2 py-5 md:py-0">
              <h3 class="text-2xl londrina">Rennaischwanz</h3>
              <h3 class="text-2xl londrina">UI / UX Designer</h3>
            </div>
            <div class="md:col-span-3 flex-1 items-center place-self-center">
              <p class="roboto">
                “It took me a long time to find a way to resuscitate my childhood heroes with the hard, veiny boner of a grown man.
                As a UI / UX designer I can combine logic and creativity without the need of programming.
                Having all the technical knowledge but still having a large share of creativity is what has drawn me to this project.
                Through this I have found an opportunity in which I can express it with parody, creative interpretation and great dirty jokes.”
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team.vue"
}
</script>

<style scoped>

</style>
