<template>
<div class="block bg-black-100 pt-40 pb-20">
  <div class="block mx-auto px-4 md:px-0 max-w-7xl">
    <iframe
        class="w-full mt-12 border-white border-2 rounded-2xl mx-auto"
        height="560"
        src="https://www.youtube.com/embed/xsltrptT_Ik?controls=0"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
  </div>
</div>
</template>

<script>
export default {
name: "Video.vue"
}
</script>

<style scoped>

</style>
